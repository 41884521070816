class NavigationController {
  constructor() {
    this.data = {
      navigation: document.querySelector('.navigation'),
      navigationCheckbox: document.querySelector('.navigation__checkbox'),
      navigationLinks: document.querySelectorAll('.navigation__link'),
      sections: document.querySelectorAll('.section'),
      navigationBreakpoint: 480,
      animationTimeout: 700,
    };
  }

  init() {
    const { navigation, navigationCheckbox } = this.data;

    if (navigation) {
      this.navigationButtonController(navigationCheckbox);
      this.scrollController();
    }
  }

  navigationButtonController(navigationButton) {
    navigationButton.addEventListener('click', e => this.handleNavigationAnimation(e));
  }

  handleNavigationAnimation() {
    const { navigation, animationTimeout } = this.data;

    if (navigation.classList.contains('navigation--open')) {
      navigation.classList.remove('navigation--open');
      navigation.classList.add('navigation--close');

      setTimeout(() => {
        navigation.classList.remove('navigation--close');
      }, animationTimeout);
    } else {
      navigation.classList.add('navigation--open');
    }
  }

  scrollToSection(e) {
    const { sections } = this.data;

    sections.forEach((section) => {
      if (section.id === e.target.dataset.info) {
        const secitonPos = section.offsetTop + window.innerHeight;

        window.scrollBy({
          top: secitonPos,
          behavior: 'smooth',
        });
      }
    });
  }

  scrollController() {
    const { navigationLinks } = this.data;

    navigationLinks.forEach((link) => {
      link.addEventListener('click', e => this.scrollToSection(e));
    });
  }
}

export default NavigationController;
