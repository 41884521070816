// import jQuery from 'jquery';
import LandingPageController from './components/LandingPage';
import FormController from './components/Form';
import NavigationController from './components/Navigation';

let main;
const $ = window.jQuery;
const { grecaptcha } = window;

class MainController {
  constructor() {
    this.data = {
    };

    this.controllers = {
      landingPageController: new LandingPageController(),
      formController: new FormController(),
      navigationController: new NavigationController(),
    };
  }

  init() {
    this.controllers.landingPageController.init();
    this.controllers.formController.init();
    this.controllers.navigationController.init();
    this.hashSessionOnReload();
  }


  hashSessionOnReload() {
    $.ajax({
      url: 'nonce.php',
      dataType: 'JSON',
      success(data) {
        const nonceInput = $('.nonce'),
              objData = data.nonce;

        nonceInput.val(objData);
      },
      error() {
        document.location.reload();
      },
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  main = new MainController();
  main.init();

  window.submitHandler = function () {
    const $form = $('.g-recaptcha').closest('form'),
          $formAction = $form.attr('action'),
          $formMethod = $form.attr('method');

    $.ajax({
      type: $formMethod,
      url: $formAction,
      data: `${$form.serialize()}&gRecaptchaResponse=${grecaptcha.getResponse()}`,
    });
  };
});
