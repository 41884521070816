const { stepsForm, NLForm } = window;
const $ = window.jQuery;

class FormController {
  constructor() {
    this.data = {
      elem: document.querySelectorAll('.elem'),
      logo: document.querySelector('.logo'),
    };
  }

  init() {
    const { elem, logo } = this.data;

    if (elem && logo) {
      this.randomForm();
    }
  }

  R(max, min) {
    return Math.random() * (max - min) + min;
  }

  randomForm() {
    const randomNumber = this.R(1, 10);

    if (randomNumber <= 5) {
      $('.form#first').remove();
      this.simFormHandler();
    } else {
      $('.form#second').remove();
      this.nlFormHandler();
    }

    $('.form').css({
      visibility: 'visible',
      opacity: '1',
    });
  }

  simFormHandler() {
    const theForm = document.getElementById('theForm');

    // eslint-disable-next-line new-cap
    const instance = new stepsForm(theForm, {
      onSubmit() {
        // hide form
        theForm.querySelector('.simform-inner').classList.add('hide');

        // let's just simulate something...
        const messageEl = theForm.querySelector('.final-message');
        messageEl.innerHTML = 'Thank you! We\'ll be in touch.';
        messageEl.classList.add('show');
      },
    });

    return instance;
  }

  nlFormHandler() {
    const nlform = document.getElementById('nl-form');
    const instance = new NLForm(nlform);

    $('#firstForm').click(() => {
      // hide form
      nlform.classList.add('hide');

      // let's just simulate something...
      const messageEl = document.querySelector('.final-message');
      messageEl.innerHTML = 'Thank you! We\'ll be in touch.';
      messageEl.classList.add('show');
    });

    return instance;
  }
}

export default FormController;
