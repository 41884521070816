import {
  TimelineMax,
  TweenLite,
  TimelineLite,
} from 'gsap';

const $ = window.jQuery;

class LandingPageController {
  constructor() {
    this.data = {
      elem: document.querySelectorAll('.elem'),
      logo: document.querySelector('.logo'),
    };
  }

  init() {
    const { elem, logo } = this.data;

    if(elem && logo) {
      const tl = new TimelineMax();

      this.FadeIn(tl, logo);
      this.ShakeElements(elem, tl);
      this.ShakeLogoHideElements(tl, logo);
      this.LogoToLetters(tl, logo);
      this.TypeWords(tl);
      this.scrollDown();
    }
  }

  shake(element, shakes, speed) {
    const tl = new TimelineLite();

    TweenLite.set(element, { x: '+=0' }); // this creates a full _gsTransform on element
    const transforms = element._gsTransform;

    // store the transform values that exist before the shake so we can return to them later
    const initProps = {
      x: transforms.x,
      y: transforms.y,
      rotation: transforms.rotation,
    };

    // shake a bunch of times
    for (let i = 0; i < shakes; i += 1) {
      tl.to(element, speed, { x: initProps.x + this.R(-2, 2), y: initProps.y + this.R(-2, 2), rotation: initProps.rotation + this.R(-2, 2) });
    }

    // return to pre-shake values
    tl.to(element, speed, {
      x: initProps.x,
      y: initProps.y,
      scale: initProps.scale,
      rotation: initProps.rotation,
    });

    return tl;
  }

  Type(element, words, speed) {
    $(element).typed({
      strings: words,
      typeSpeed: speed,
    });
  }

  R(max, min) {
    return Math.random() * (max - min) + min;
  }

  FadeIn(tl, logo) {
    tl
      .set(logo, { className: '-=init' })
      .set('.elem', { className: '-=init' }, 0.4);
  }

  ShakeElements(elem, tl) {
    for (let i = 0; i < elem.length; i += 1) {
      tl.add(this.shake(elem[i], 10, 0.3), 1 + (i * 0.03));
    }
  }

  ShakeLogoHideElements(tl, logo) {
    tl
      .add(this.shake(logo, 13, 0.25), 1.2)
      .fromTo('#slash, #equals, #intersection, #vline, #hline, #square, #bracket', 1, {}, { opacity: 0 }, 3)
      .to('#less', 1, {
        css: {
          width: '40px',
          left: '+=7.2%',
          bottom: '5%',
          opacity: 1,
          rotation: '-90deg',
        },
      }, 4.5);
  }

  LogoToLetters(tl, logo) {
    // 353 569
    const $window = $(window);
    if ($window.width() < 530) {
      tl.to(logo, 1, { left: '-=40%' }, 4.6);
    } else if ($window.width() < 800) {
      tl.to(logo, 1, { left: '-=30%' }, 4.6);
    } else {
      tl.to(logo, 1, { left: '-=20%' }, 4.6);
    }

    tl
      .to('.R_letter_logo', 1, {
        top: -80,
        right: 0,
        rotation: '-=270',
        opacity: 0,
      }, 4.6)
      .to('.R_letter_type', 1, {
        top: -80,
        right: 0,
        rotation: '-=270',
        opacity: 1,
      }, 4.6)
      .to('.D_letter_type', 1, { left: '+=31', rotation: '-=90', opacity: 1 }, 4.6);

    if ($window.width() < 1190) {
      tl.to('.D_letter_logo', 1, {
        bottom: '+=120',
        left: '+=21',
        rotation: '-=90',
        opacity: 0,
      }, 4.6);
    } else {
      tl.to('.D_letter_logo', 1, {
        bottom: '-=30',
        left: '+=21',
        rotation: '-=90',
        opacity: 0,
      }, 4.6);
    }
  }

  TypeWords(tl) {
    tl
      .call(this.Type, ['.firstWord', ['esearch <br>evelopment', 'esponsive <br>esign', 'apid <br>ebugging', 'eliable <br>udes', 'eally <br>ope', 'ubber <br>uck'], 30], 5.6);
  }

  scrollDown() {
    $('#less').click(() => {
      const elemHeight = $('.landing-section').height();

      $('html, body').animate({
        scrollTop: elemHeight,
      }, 1000);
    });
  }
}

export default LandingPageController;
